import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BarChart from '../../components/tradingviewinvestorrelations';
import BreadcrumbMenu from '../../components/menus/bcmenu';
import InlineBodyTransform from '../../components/inlinebodytransform';
import Layout from '../../components/layout';
import React from 'react';
import SEO from '../../components/seo';
import SideNavBar from '../../components/menus/navbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(location)

  //  Page
  const post = data.nodeInvestorRelations
  //let barchartValue = post.field_barchart_url

  let backgroundImage = ''
  if (post.relationships.field_image_banner != null) {
    backgroundImage = post.relationships.field_image_banner.localFile.childImageSharp.investorrelations.src
    backgroundImage = encodeURI(backgroundImage)
  }

  return (
    <Layout>
      <SEO 
        title={post.title}
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${backgroundImage})`}}>
                {post.title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
                <MDBRow center>
                  <MDBCol xs="12" md="12" lg="7" className="col-12 px-3">
                    <InlineBodyTransform bodyValue={post.body} />
                  </MDBCol>
                  <MDBCol xs="12" md="12" lg="5" className="col-12 px-3" style={{minWidth: "320px"}}>
                    <BarChart title={post.title} />
                  </MDBCol>
                </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
query($id: String!) {
  nodeInvestorRelations(id: { eq: $id }) {
    title
    body {
      value
    }
    created
    field_barchart_url
    relationships {
      field_image_banner {
        localFile{
          childImageSharp {
            investorrelations: original {
              src
            }
          }
        }
      }
    }
  }

  # Menu relation
  allMenuLinkContentMenuLinkContent {
    edges {
      node {
        title
        link {
          uri
        }
        menu_name
      }
    }
  }
}
`