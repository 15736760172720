import { Link, graphql, useStaticQuery } from "gatsby";
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdbreact';

import React from 'react';
import styled from 'styled-components';

let gmdv = require(`../../ghc_config`);

const MDBBreadcrumbComp = styled(MDBBreadcrumb)`
  background-color: transparent!important;
  padding: 0em;
  margin: 0.3em 0em;
  font-size: 0.9em;
`

const BreadcrumbPage = (props) => {

  const data = useStaticQuery(graphql`{
    allMenuLinkContentMenuLinkContent(sort: {fields: [weight], order: ASC}) {
      edges {
        node {
          drupal_id
          title
          link {
              uri
              title
          }
          drupal_parent_menu_item
          menu_name
        }
      }
      totalCount
    }
  }`)

  let bcvalues = (<MDBBreadcrumbItem><Link to="/">Home</Link></MDBBreadcrumbItem>)
  let menuData = data.allMenuLinkContentMenuLinkContent.edges
  let location = ``
  if (props.location.pathname) {
    location = props.location.pathname
  }
  else {
    location = props.location
  }
  
  let pageTitle = menuData.filter(item => {
    return (item.node.link.uri.toLowerCase().replace('internal:', '') === location.toLowerCase().replace(/\/$/, "") 
    && item.node.menu_name.toLowerCase().includes(gmdv.getMenuid(location.toLowerCase().replace(/\/$/, ""))))
  });

  let pageTitleRender = null
  let refidValue = null
  let parentValues = null
  let parentValuesRender = null
  if (pageTitle[0]) {
    pageTitleRender = (<MDBBreadcrumbItem>{pageTitle[0].node.title}</MDBBreadcrumbItem>)
    if (pageTitle[0].node.drupal_parent_menu_item) {
      refidValue = pageTitle[0].node.drupal_parent_menu_item.toLowerCase().replace('menu_link_content:', '')
      parentValues = menuData.filter(item => {return refidValue === item.node.drupal_id.toLowerCase()});
      parentValuesRender = (
        <MDBBreadcrumbItem>
          <Link to={parentValues[0].node.link.uri.toLowerCase().replace('internal:', '')}>
            {parentValues[0].node.title}
          </Link>
        </MDBBreadcrumbItem>
      )
    }
  }

  bcvalues = (<>
      {bcvalues}
      {parentValuesRender}
      {pageTitleRender}
    </>)

  return (
    <MDBBreadcrumbComp>
      {bcvalues}
    </MDBBreadcrumbComp>
  );
};

export default BreadcrumbPage;